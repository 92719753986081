// this must be the first import to set the path of assets based on the
// environment the app is running in
require('./bootstrap.browser')

// polyfills
require('lib/polyfill')

require.ensure([], () => {

    const React = require('react')
    const { hydrate } = require('react-dom')
    const { Router, browserHistory, match } = require('react-router')
    const AppContext = require('app/app_context')
    const insertCss = require('lib/insert_css_browser')
    const MarionetteBridge = require('app/app_bridge')
    const DataWarehouse = require('lib/data_warehouse')
    const config = require('lib/config')
    const funnels = require('lib/funnels')
    const Raven = require('lib/raven')
    const ProfitWell = require('lib/profitwell')
    const dom_json = require('lib/dom_json')
    const log = require('log').create('index.browser') // eslint-disable-line

    // require favicons so they're included in the client build
    // should match requires in index.server.js
    require('./images/favicons/favicon_48.png')
    require('./images/favicons/favicon_70.png')
    require('./images/favicons/favicon_150.png')
    require('./images/favicons/favicon_180.png')
    require('./images/favicons/favicon_192.png')
    require('./images/favicons/favicon_310.png')
    require('./images/favicons/favicon_pad_310.png')
    require('./images/favicons/favicon_wide_310.png')

    // hoist common modules into app bundle
    require('app/page')
    require('module/dialog/dialog')

    // initialize Modernizr
    require('vendor/modernizr')

    // get context data from DOM node and initialize Raven
    let contextData
    try {
        contextData = dom_json.getJsonFromDomNode('.contextData')
    } catch (err) {
        log.error(err)
    }

    // initialize config store with context data
    config.initialize(contextData.globals.CONFIG)

    // initialize funnels with context data
    funnels.initialize(contextData.globals.funnels)

    // initialize raven
    Raven.initialize({
        dsn: config.get('sentry.dsn.browser.client'),
        environment: contextData.globals.NODE_ENV,
        commit: contextData.globals.COMMIT_SHA,
    })
    if (contextData.viewer.user_name) {
        Raven.setUser({
            id: contextData.viewer.user_name,
        })
    }
    // expose Raven on the window
    window.Raven = Raven

    // initialize ProfitWell with viewer
    ProfitWell.initialize({
        viewer: contextData.viewer,
    })

    // marionette app 'bridge' for supporting legacy modules. have to create this
    // early so that singleton modules like globals and viewer are properly
    // initialized before other modules reference them
    // eslint-disable-next-line no-unused-vars
    const bridge = new MarionetteBridge(contextData)

    // initialize Data Warehouse tracking
    // eslint-disable-next-line no-unused-vars
    const data_warehouse = new DataWarehouse(contextData.globals)

    const { getRoutes } = require('app/routes')

    // get route configuration
    const routes = getRoutes(contextData)

    // handler for router updates
    function onUpdate() {
        const path = this.state.location.pathname

        // TODO revisit. for now, don't scroll these (this) paths.
        if (path === '/features' || path.indexOf('/features/') === 0) return

        window.scrollTo(0, 0)
    }

    match({ history: browserHistory, routes }, (err, redirect, renderProps) => {
        // trying to see if we ever get a redirect here
        // re: https://github.com/aboutdotme/main/issues/3731
        log('err, redirect, props', err, redirect, renderProps)

        const markup = (
            <AppContext
                insertCss={insertCss}
                bridge={bridge}
                {...contextData}
                >
                <Router
                    onUpdate={onUpdate}
                    {...renderProps}
                    />
            </AppContext>
        )

        const el = document.querySelector('.page-container')

        hydrate(markup, el)

    })

}, 'app')
