/* eslint-disable no-extend-native */
/* eslint-disable no-self-compare */
// polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes
if (!Array.prototype.includes) {
    Array.prototype.includes = function includes(searchElement /* , fromIndex*/) {
        if (__DEBUG__ && this == null) {
            throw new TypeError('Array.prototype.includes called on null or undefined')
        }

        const O = Object(this)
        const len = parseInt(O.length, 10) || 0
        if (len === 0) {
            return false
        }
        const n = parseInt(arguments[1], 10) || 0
        let k
        if (n >= 0) {
            k = n
        } else {
            k = len + n
            if (k < 0) { k = 0 }
        }
        let currentElement
        while (k < len) {
            currentElement = O[k]
            if (searchElement === currentElement ||
         (searchElement !== searchElement && currentElement !== currentElement)) { // NaN !== NaN
                return true
            }
            k++
        }
        return false
    }
}
